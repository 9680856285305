import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Fade,
  Grid,
  Paper,
  Skeleton,
  Tooltip,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useRef, useState } from "react";
import { Edit } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import UploadtoCloud from "../../assets1/UploadtoCloud.svg";
import DeleteIcon from "../../assets/DeleteIcon.svg";
import { toast } from "react-toastify";
import * as XLSX from "xlsx";
import BXICOIN from "../../assets/GenieImg/BXICOIN.png";
import { ProductCountOnPlateform } from "../../redux/action/AdminDashBoard/TotalProducts";
import { CompanyCountOnPlateform } from "../../redux/action/AdminDashBoard/TotalCompanys";

import { MemberShipCountOnPlateform } from "../../redux/action/AdminDashBoard/TotalMemberShip";

import { CreditIssueCountsOnPlateform } from "../../redux/action/AdminDashBoard/TotalcreditIssue";

import { AllAmountOnPlateform } from "../../redux/action/AdminDashBoard/TotalAmount";

import CommaSeprator from "../../common/CommaSeparator";
import InventoryBox from "../../assets/InventoryBox.svg";
import { styled } from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { GetTokenFunc } from "../../utils/GetToken";
import { useFetchMemberShipPlan } from "./useMembershipplan";
import { useForm } from "react-hook-form";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import dayjs from "dayjs";

const Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [files, setFiles] = useState([]);
  const [FileNameErrorfordimanesion, setFileNameErrorForDimension] = useState();
  const [FileNameErrorForSize, setFileNameErrorForSize] = useState();
  const [isDisabled, setIsDisabled] = useState(true);
  const [buyNowButton, setBuyNowButton] = useState([]);
  const [activeButtonUrl, setActiveButtonUrl] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [updateStatus, setUpdateStatus] = useState("");
  const [optionFilter, setOptionFilter] = useState("week");
  const [excelData, setExcelData] = useState([]);
  const [refundexcelData, setrefundExcelData] = useState([]);
  const [excelLoading, setExcelLoading] = useState(false);
  const [refundexcelLoading, setrefundExcelLoading] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [refundstartDate, setrefundStartDate] = useState(null);
  const [refundendDate, setrefundEndDate] = useState(null);
  const [storeTDS, setStoreTDS] = useState("");

  const handleDateRangeChange = (newDates) => {
    // Convert JavaScript Date objects to dayjs objects
    const newStartDate = dayjs(newDates[0]);
    const newEndDate = dayjs(newDates[1]);

    // Update state with the new dates
    setStartDate(newStartDate);
    setEndDate(newEndDate);
    setExcelLoading(true);
  };

  const handleRefundDateRangeChange = (newDates) => {
    // Convert JavaScript Date objects to dayjs objects
    const newStartDate = dayjs(newDates[0]);
    const newEndDate = dayjs(newDates[1]);

    // Update state with the new dates
    setrefundStartDate(newStartDate);
    setrefundEndDate(newEndDate);
    setrefundExcelLoading(true);
  };

  useEffect(() => {
    dispatch(ProductCountOnPlateform());
    dispatch(CompanyCountOnPlateform());
    dispatch(MemberShipCountOnPlateform());
    dispatch(CreditIssueCountsOnPlateform());
    dispatch(AllAmountOnPlateform());
  }, [dispatch]);

  const {
    data: getMemberShipPlan,
    isLoading: MemberShipPlanLoading,
    refetch: MemberShipPlanRefetch,
  } = useFetchMemberShipPlan();

  const [MemberShipData, setMemberShipData] = useState({});

  useEffect(() => {
    setMemberShipData({
      BasicPlanId: getMemberShipPlan?.at(0)?._id,
      BasicPlanType: getMemberShipPlan?.at(0)?.PlanType,
      BasicPlanName: getMemberShipPlan?.at(0)?.PlanName,
      BasicPlanPrice: getMemberShipPlan?.at(0)?.PlanPrice,
      // BasicTDS: getMemberShipPlan?.at(0)?.TDS,
      BasicGST: getMemberShipPlan?.at(0)?.GST,
      BasicisActive: getMemberShipPlan?.at(0)?.isActive,
      AdvancedPlanId: getMemberShipPlan?.at(1)?._id,
      AdvancedPlanType: getMemberShipPlan?.at(1)?.PlanType,
      AdvancedPlanName: getMemberShipPlan?.at(1)?.PlanName,
      AdvancedPlanPrice: getMemberShipPlan?.at(1)?.PlanPrice,
      // AdvancedTDS: getMemberShipPlan?.at(1)?.TDS,
      AdvancedGST: getMemberShipPlan?.at(1)?.GST,
      AdvancedisActive: getMemberShipPlan?.at(1)?.isActive,
    });
  }, [getMemberShipPlan]);

  const [AllCreditdue, setAllCreditdue] = useState();

  const GetAllCreditdue = async () => {
    try {
      const response = await axios.get(`/wallet/get_all_creditdue`, {
        withCredentials: true,
      });
      setAllCreditdue(response?.data?.totalBalance);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    GetAllCreditdue();
  }, []);

  useEffect(() => {
    MemberShipPlanRefetch();
    fetchBuyNow();
  }, []);

  const fetchBuyNow = async () => {
    try {
      const response = await axios.get(
        "/api/v1/admin/getProductImageInBanner",
        {
          headers: {
            withCredentials: true,
            authorization: `bearer ${GetTokenFunc()}`,
          },
        }
      );
      const buyNowData = response?.data.image[0];
      setBuyNowButton(buyNowData);
      setActiveButtonUrl(buyNowData.imageUrl);
      setIsActive(buyNowData.togal);
    } catch (error) {
      console.error(error);
    }
  };

  const updateBuyNow = async () => {
    if (activeButtonUrl.trim() === "") {
      toast.error(`Buy Now URL cannot be empty.`, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }
    try {
      const response = await axios.post(
        "/api/v1/admin/ProductUploadUrlInBanner",
        {
          imageUrl: activeButtonUrl,
          togal: isActive,
        },
        {
          headers: {
            withCredentials: true,
            authorization: `bearer ${GetTokenFunc()}`,
          },
        }
      );
      toast.success(`Buy Now updated successfully.`, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setMemberShipData({ ...MemberShipData, [name]: value });
  };

  const handleSwitchChange = (e, name) => {
    const checked = e.target.checked;
    setMemberShipData({ ...MemberShipData, [name]: checked });
  };

  const { ProductCounts, loading } = useSelector(
    (state) => state.AllProductCount
  );

  const { CompanyCounts } = useSelector((state) => state.AllCompanyCount);

  const { MemberShipCounts } = useSelector((state) => state.AllMemberShipCount);

  const { CreditIssueCounts } = useSelector(
    (state) => state.AllCreditIssueCount
  );

  const { TotalAmount } = useSelector((state) => state.AllAmount);

  const inputRef = useRef(null);

  const handleDrop = (event) => {
    event.preventDefault();
    setFiles(event.dataTransfer.files);
  };
  const handleDragOver = (event) => {
    event.preventDefault();
  };

  // const handleChangeFiles = (event) => {
  //   setFiles(event.target.files);
  // };

  const handleChangeFiles = (event) => {
    const newFiles = Array.from(event.target.files);
    const duplicateFiles = newFiles.filter((file) =>
      files.some((existingFile) => existingFile.name === file.name)
    );

    if (duplicateFiles.length > 0) {
      return toast.error("Duplicate Files are not allowed", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const uploadAvatar = async () => {
    if (files === null || files.length === 0) {
      return toast.error("Please Add File", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }

    const maxFileSize = 5 * 1024 * 1024;
    const minWidth = 1300;
    const maxWidth = 1340;
    const minHeight = 230;
    const maxHeight = 270;
    const allowedFormats = [
      "image/png",
      "image/jpeg",
      "image/jpg",
      "image/svg+xml",
      "image/webp",
    ];

    const errorMessages = [];
    const uploadPromises = files.map(async (selectedFile) => {
      if (selectedFile.size > maxFileSize) {
        errorMessages.push(
          `File ${selectedFile.name} exceeds the maximum allowed size (5 MB)`
        );
        return false;
      }

      if (!allowedFormats.includes(selectedFile.type)) {
        errorMessages.push(
          `File ${selectedFile.name} has an invalid format. Please upload a PNG, JPG, JPEG, SVG, or WEBP file.`
        );
        return false;
      }

      return new Promise((resolve) => {
        const img = new Image();
        img.src = URL.createObjectURL(selectedFile);
        img.onload = async () => {
          if (
            img.width < minWidth ||
            img.width > maxWidth ||
            img.height < minHeight ||
            img.height > maxHeight
          ) {
            errorMessages.push(
              `File ${selectedFile.name} dimensions must be between 1300x230 and 1340x270 pixels`
            );
            resolve(false);
          } else {
            try {
              const fileData = new FormData();
              fileData.append("file", selectedFile);

              await axios.post(`/api/v1/admin/bxiuploadbanner`, fileData, {
                headers: {
                  "Content-Type": "multipart/form-data",
                  authorization: `bearer ${GetTokenFunc()}`,
                },
              });
              toast.success(`File Uploaded: ${selectedFile.name}`, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
              resolve(true);
            } catch (error) {
              errorMessages.push(`Error uploading file ${selectedFile.name}`);
              resolve(false);
            }
          }
        };
      });
    });

    // Wait for all uploads to finish
    const results = await Promise.all(uploadPromises);
    const failedUploads = results.filter((result) => result === false);

    // Show all error messages in one toast if there are any
    if (errorMessages.length > 0) {
      toast.error(errorMessages.join(" "), {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }

    // Clear files if all uploads succeeded
    if (failedUploads.length === 0) {
      setFiles([]); // Clear files if all uploads succeeded
    }
  };

  const IOSSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));

  const handleSubmitBasic = async (membershipId) => {
    try {
      const response = await axios.post(
        `membership/updatemembership?membershipId=${membershipId}`,
        {
          PlanName: MemberShipData?.BasicPlanName,
          PlanPrice: MemberShipData?.BasicPlanPrice,
          PlanType: MemberShipData?.BasicPlanType,
          // TDS: MemberShipData?.BasicTDS,
          GST: MemberShipData?.BasicGST,
          isActive: MemberShipData?.BasicisActive,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 200) {
        toast.success(`Membership Basic plan updated successfully.`, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        console.error(
          `Failed to update membership plan with ID ${membershipId}.`
        );
      }
    } catch (error) {
      console.error(
        "An error occurred while updating the membership plan:",
        error
      );
    }
  };
  const handleSubmitAdvanced = async (membershipId) => {
    try {
      const response = await axios.post(
        `membership/updatemembership?membershipId=${membershipId}`,
        {
          PlanName: MemberShipData?.AdvancedPlanName,
          PlanPrice: MemberShipData?.AdvancedPlanPrice,
          PlanType: MemberShipData?.AdvancedPlanType,
          // TDS: MemberShipData?.AdvancedTDS,
          GST: MemberShipData?.AdvancedGST,
          isActive: MemberShipData?.AdvancedisActive,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 200) {
        toast.success(`Membership Advanced plan updated successfully.`, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        console.error(
          `Failed to update membership plan with ID ${membershipId}.`
        );
      }
    } catch (error) {
      console.error(
        "An error occurred while updating the membership plan:",
        error
      );
    }
  };

  useEffect(() => {
    const getExcelData = async () => {
      try {
        const data = await axios.get(
          `/api/v1/admin/DownloadFinancialDataOnExcel?startDate=${startDate}&endDate=${endDate}`,
          {
            headers: {
              authorization: `Bearer ${GetTokenFunc()}`,
            },
          }
        );
        setExcelData(data?.data?.OrderData);
      } catch (error) {
        console.error(error);
      } finally {
        setExcelLoading(false);
      }
    };
    if (endDate && startDate) {
      getExcelData();
    }
    const fetch_tds = async () => {
      try {
        const response = await axios.get(`Update_TDS_GST/get_admin_tds`);
        setStoreTDS(response?.data);
      } catch (error) {}
    };
    fetch_tds();
  }, [endDate]);

  useEffect(() => {
    const getExcelData = async () => {
      try {
        const data = await axios.get(
          `/api/v1/admin/DownloadFinancialDataforRefundOnExcel?startDate=${refundstartDate}&endDate=${refundendDate}`,
          {
            headers: {
              authorization: `Bearer ${GetTokenFunc()}`,
            },
          }
        );
        setrefundExcelData(data?.data?.OrderData);
      } catch (error) {
        console.error(error);
      } finally {
        setrefundExcelLoading(false);
      }
    };
    if (refundendDate && refundstartDate) {
      getExcelData();
    }
    const fetch_tds = async () => {
      try {
        const response = await axios.get(`Update_TDS_GST/get_admin_tds`);
        setStoreTDS(response?.data);
      } catch (error) {}
    };
    fetch_tds();
  }, [refundendDate]);

  const flattenUserData = (userData) => {
    return userData.map((data, index) => {
      let TotalAdditionalCostWtihoutGSTInBXI = 0;
      let totaladditionalcostGstinrupee = 0;
      let totaladditionalcostGstinbxi = 0;
      let TotalPriceWithoutGST = 0;

      const getTotalGST = () => {
        return (
          ((data?.INRDetails?.totalGstAmount ||
            data?.INRDetails?.TotalGstAmount) ??
            0) +
          ((data?.INRDetails?.totalGstInrOfAdCost ||
            data?.INRDetails?.TotalGstInrOfAdCost) ??
            0) +
          (data?.INRDetails?.InsuranceCostGstValue ?? 0) +
          (data?.INRDetails?.packagingCostGSTValue ?? 0) +
          (data?.INRDetails?.bxiCommisionOnTransportationGST ?? 0) +
          (data?.BuyerChoosedTransportation?.TransportationType ===
          "notransportation"
            ? 0
            : ((data?.InvoiceData?.Transportationdata?.transportationfee || 0) *
                (data?.InvoiceData?.Transportationdata?.gstFee || 0)) /
              100)
        );
      };

      data?.PurchaseOrderData?.ProductData?.map((el, idx) => {
        if (data?.IsVoucher) {
          TotalAdditionalCostWtihoutGSTInBXI +=
            el?.AdditionalCost?.TotalAdditionalCostWtihoutGSTInBXI;
        } else {
          TotalAdditionalCostWtihoutGSTInBXI +=
            el?.AdditionCost?.TotalAdditionalCostWtihoutGSTInBXI;
        }
        TotalPriceWithoutGST += el?.TotalPriceWithoutGST;

        if (
          el &&
          (el?.AdditionCost?.AdditionCostArray?.length > 0 ||
            el?.AdditionalCost?.AdditionCostArrayData?.length > 0 ||
            el?.AdditionCost?.AdditionCostArrayData?.length)
        ) {
          const additionalCostArray =
            el?.AdditionCost?.AdditionCostArray?.length > 0
              ? el?.AdditionCost?.AdditionCostArray
              : el?.AdditionCost?.AdditionCostArrayData?.length > 0
              ? el?.AdditionCost?.AdditionCostArrayData
              : el?.AdditionalCost?.AdditionCostArrayData;

          additionalCostArray.forEach((item, index) => {
            if (item.currencyType === "₹") {
              totaladditionalcostGstinrupee +=
                (item.TotalTaxableAmount * item.AdCostGST) / 100;
            } else if (item.currencyType === "BXITokens") {
              totaladditionalcostGstinbxi +=
                (item.TotalTaxableAmount * item.AdCostGST) / 100;
            }
          });
        }
      });

      return {
        OrderNumber: data?.OrderId,
        OrderDate: new Date(data?.createdAt).toLocaleDateString("en-GB", {
          year: "2-digit",
          month: "2-digit",
          day: "2-digit",
        }),
        OrderPaymentDate: new Date(
          data?.TaxInvoice?.createdAt
        ).toLocaleDateString("en-GB", {
          year: "2-digit",
          month: "2-digit",
          day: "2-digit",
        }),
        BuyerCompanyName:
          data?.PurchaseOrderData?.BuyerDetails?.BuyerCompanyName,
        SellerCompanyName:
          data?.PurchaseOrderData?.SellerDetails?.SellerCompanyName,
        SellerPANNumber: data?.companyData?.bankdetails?.pan,
        ProductCategoryName:
          data?.PurchaseOrderData?.ProductData[0]?.ProductTypeName,
        PurchaseOrderNO: data?.PurchaseOrderData?.PoNumber,
        FINumber: data?.TaxInvoice?.FINumber,
        FInvoiceDate: new Date(data?.TaxInvoice?.createdAt).toLocaleDateString(
          "en-GB",
          {
            year: "2-digit",
            month: "2-digit",
            day: "2-digit",
          }
        ),
        ProductHSN: data?.TaxInvoice?.ProductData[0]?.HSN,
        TotalProductCoins: TotalPriceWithoutGST?.toFixed(2),
        TotalAdCostCoins: TotalAdditionalCostWtihoutGSTInBXI?.toFixed(2),
        TotalAdCostINR: data?.INRDetails?.TotalValueInrOfAdCost
          ? (
              data?.INRDetails?.TotalValueInrOfAdCost +
              (data?.INRDetails?.packagingCost || 0)
            )?.toFixed(2)
          : data?.INRDetails?.totalValueInrOfAdCost
          ? (
              data?.INRDetails?.totalValueInrOfAdCost +
              (data?.INRDetails?.packagingCost || 0)
            )?.toFixed(2)
          : (
              (data?.POTotals?.TotalAdditionalCostInRupee || 0) +
              (data?.INRDetails?.packagingCost || 0)
            )?.toFixed(2),
        SellersLogisiticCost:
          data?.BuyerChoosedTransportation?.TransportationType ===
          "notransportation"
            ? 0
            : data?.InvoiceData?.Transportationdata?.transportationfee || 0,
        ProductGSTAmount: data?.IsMedia
          ? data?.INRDetails?.TotalGstAmount?.toFixed(2)
          : data?.INRDetails?.totalGstAmount?.toFixed(2),
        AdCostGSTAmount: (
          totaladditionalcostGstinbxi +
          totaladditionalcostGstinrupee +
          (data?.INRDetails?.packagingCostGSTValue || 0)
        )?.toFixed(2),
        // LogisiticGST:
        //   data?.BuyerChoosedTransportation?.TransportationType ===
        //   "notransportation"
        //     ? 0
        //     : data?.InvoiceData?.Transportationdata?.gstFee || 0,
        LogisiticGSTAmount:
          data?.BuyerChoosedTransportation?.TransportationType ===
          "notransportation"
            ? 0
            : ((data?.InvoiceData?.Transportationdata?.transportationfee || 0) *
                (data?.InvoiceData?.Transportationdata?.gstFee || 0)) /
              100,
        TotalGstAmount: getTotalGST()?.toFixed(2),

        CommissionInvoiceNumber: data?.commissionData?.CommitionId,
        CommissionInvoiceDate: data?.commissionData?.InvoiceDate
          ? new Date(data?.commissionData?.InvoiceDate).toLocaleDateString(
              "en-GB",
              {
                year: "2-digit",
                month: "2-digit",
                day: "2-digit",
              }
            )
          : "",
        HSNCode: "996211",
        TotalBxiCommission: data?.IsMedia
          ? data?.INRDetails?.TotalBxiCommission?.toFixed(2)
          : data?.INRDetails?.totalBxiCommission?.toFixed(2),
        "BxiCommisionGST(18%)": data?.IsMedia
          ? data?.INRDetails?.BxiCommisionGST?.toFixed(2)
          : data?.INRDetails?.bxiCommisionGST?.toFixed(2),
        TotalValue: data?.IsMedia
          ? (
              data?.INRDetails?.TotalBxiCommission +
              data?.INRDetails?.BxiCommisionGST
            )?.toFixed(2)
          : (
              data?.INRDetails?.totalBxiCommission +
              data?.INRDetails?.bxiCommisionGST
            )?.toFixed(2),
        // TDSOnCommission: data?.INRDetails?.TDS?.toFixed(2),
        // [`TotalTDS (${storeTDS?.TDSData?.TDS}%)`]:
        "TotalTDS(0.1%)": data?.INRDetails?.TDS?.toFixed(2),
        "FromBuyerToSeller(Coin)": (
          (TotalAdditionalCostWtihoutGSTInBXI || 0) +
          (TotalPriceWithoutGST || 0) -
          data?.INRDetails?.TDS
        ).toFixed(2),
        "FromBuyerToSeller(Inr)": data?.INRDetails?.TotalInrToPay?.toFixed(2),
        "FromSellertoBXI(Inr)": data?.IsMedia
          ? (
              data?.INRDetails?.TotalBxiCommission +
              data?.INRDetails?.BxiCommisionGST +
              data?.INRDetails?.TDS
            )?.toFixed(2)
          : (
              data?.INRDetails?.totalBxiCommission +
              data?.INRDetails?.bxiCommisionGST +
              data?.INRDetails?.TDS
            )?.toFixed(2),
        TotalInvoiceValue: (
          (TotalAdditionalCostWtihoutGSTInBXI || 0) +
          (TotalPriceWithoutGST || 0) -
          (data?.INRDetails?.TDS || 0) +
          data?.INRDetails?.TotalInrToPay
        )?.toFixed(2),
      };
    });
  };
  const RefundflattenUserData = (userData) => {
    return userData.map((data, index) => {
      let TotalAdditionalCostWtihoutGSTInBXI = 0;
      let totaladditionalcostGstinrupee = 0;
      let totaladditionalcostGstinbxi = 0;
      let TotalPriceWithoutGST = 0;

      const getTotalGST = () => {
        return (
          ((data?.INRDetails?.totalGstAmount ||
            data?.INRDetails?.TotalGstAmount) ??
            0) +
          ((data?.INRDetails?.totalGstInrOfAdCost ||
            data?.INRDetails?.TotalGstInrOfAdCost) ??
            0) +
          (data?.INRDetails?.InsuranceCostGstValue ?? 0) +
          (data?.INRDetails?.packagingCostGSTValue ?? 0) +
          (data?.INRDetails?.bxiCommisionOnTransportationGST ?? 0) +
          (data?.BuyerChoosedTransportation?.TransportationType ===
          "notransportation"
            ? 0
            : ((data?.InvoiceData?.Transportationdata?.transportationfee || 0) *
                (data?.InvoiceData?.Transportationdata?.gstFee || 0)) /
              100)
        );
      };

      data?.PurchaseOrderData?.ProductData?.map((el, idx) => {
        if (data?.IsVoucher) {
          TotalAdditionalCostWtihoutGSTInBXI +=
            el?.AdditionalCost?.TotalAdditionalCostWtihoutGSTInBXI;
        } else {
          TotalAdditionalCostWtihoutGSTInBXI +=
            el?.AdditionCost?.TotalAdditionalCostWtihoutGSTInBXI;
        }
        TotalPriceWithoutGST += el?.TotalPriceWithoutGST;

        if (
          el &&
          (el?.AdditionCost?.AdditionCostArray?.length > 0 ||
            el?.AdditionalCost?.AdditionCostArrayData?.length > 0 ||
            el?.AdditionCost?.AdditionCostArrayData?.length)
        ) {
          const additionalCostArray =
            el?.AdditionCost?.AdditionCostArray?.length > 0
              ? el?.AdditionCost?.AdditionCostArray
              : el?.AdditionCost?.AdditionCostArrayData?.length > 0
              ? el?.AdditionCost?.AdditionCostArrayData
              : el?.AdditionalCost?.AdditionCostArrayData;

          additionalCostArray.forEach((item, index) => {
            if (item.currencyType === "₹") {
              totaladditionalcostGstinrupee +=
                (item.TotalTaxableAmount * item.AdCostGST) / 100;
            } else if (item.currencyType === "BXITokens") {
              totaladditionalcostGstinbxi +=
                (item.TotalTaxableAmount * item.AdCostGST) / 100;
            }
          });
        }
      });

      return {
        OrderNumber: data?.OrderId,
        OrderDate: new Date(data?.createdAt).toLocaleDateString("en-GB", {
          year: "2-digit",
          month: "2-digit",
          day: "2-digit",
        }),
        OrderPaymentDate: new Date(
          data?.TaxInvoice?.createdAt
        ).toLocaleDateString("en-GB", {
          year: "2-digit",
          month: "2-digit",
          day: "2-digit",
        }),
        BuyerCompanyName:
          data?.PurchaseOrderData?.BuyerDetails?.BuyerCompanyName,
        SellerCompanyName:
          data?.PurchaseOrderData?.SellerDetails?.SellerCompanyName,
        SellerPANNumber: data?.companyData?.bankdetails?.pan,
        ProductCategoryName:
          data?.PurchaseOrderData?.ProductData[0]?.ProductTypeName,
        PurchaseOrderNO: data?.PurchaseOrderData?.PoNumber,
        FINumber: data?.TaxInvoice?.FINumber,
        FInvoiceDate: new Date(data?.TaxInvoice?.createdAt).toLocaleDateString(
          "en-GB",
          {
            year: "2-digit",
            month: "2-digit",
            day: "2-digit",
          }
        ),
        ProductHSN: data?.TaxInvoice?.ProductData[0]?.HSN,
        TotalProductCoins: TotalPriceWithoutGST?.toFixed(2),
        TotalAdCostCoins: TotalAdditionalCostWtihoutGSTInBXI?.toFixed(2),
        TotalAdCostINR: data?.INRDetails?.TotalValueInrOfAdCost
          ? (
              data?.INRDetails?.TotalValueInrOfAdCost +
              (data?.INRDetails?.packagingCost || 0)
            )?.toFixed(2)
          : data?.INRDetails?.totalValueInrOfAdCost
          ? (
              data?.INRDetails?.totalValueInrOfAdCost +
              (data?.INRDetails?.packagingCost || 0)
            )?.toFixed(2)
          : (
              (data?.POTotals?.TotalAdditionalCostInRupee || 0) +
              (data?.INRDetails?.packagingCost || 0)
            )?.toFixed(2),
        SellersLogisiticCost:
          data?.BuyerChoosedTransportation?.TransportationType ===
          "notransportation"
            ? 0
            : data?.InvoiceData?.Transportationdata?.transportationfee || 0,
        ProductGSTAmount: data?.IsMedia
          ? data?.INRDetails?.TotalGstAmount?.toFixed(2)
          : data?.INRDetails?.totalGstAmount?.toFixed(2),
        AdCostGSTAmount: (
          totaladditionalcostGstinbxi +
          totaladditionalcostGstinrupee +
          (data?.INRDetails?.packagingCostGSTValue || 0)
        )?.toFixed(2),
        // LogisiticGST:
        //   data?.BuyerChoosedTransportation?.TransportationType ===
        //   "notransportation"
        //     ? 0
        //     : data?.InvoiceData?.Transportationdata?.gstFee || 0,
        LogisiticGSTAmount:
          data?.BuyerChoosedTransportation?.TransportationType ===
          "notransportation"
            ? 0
            : ((data?.InvoiceData?.Transportationdata?.transportationfee || 0) *
                (data?.InvoiceData?.Transportationdata?.gstFee || 0)) /
              100,
        TotalGstAmount: getTotalGST()?.toFixed(2),
        CommissionInvoiceNumber: data?.commissionData?.CommitionId,
        CommissionInvoiceDate: data?.commissionData?.InvoiceDate
          ? new Date(data?.commissionData?.InvoiceDate).toLocaleDateString(
              "en-GB",
              {
                year: "2-digit",
                month: "2-digit",
                day: "2-digit",
              }
            )
          : "",
        HSNCode: "996211",
        TotalBxiCommission: data?.IsMedia
          ? data?.INRDetails?.TotalBxiCommission?.toFixed(2)
          : data?.INRDetails?.totalBxiCommission?.toFixed(2),
        "BxiCommisionGST(18%)": data?.IsMedia
          ? data?.INRDetails?.BxiCommisionGST?.toFixed(2)
          : data?.INRDetails?.bxiCommisionGST?.toFixed(2),
        TotalValue: data?.IsMedia
          ? (
              data?.INRDetails?.TotalBxiCommission +
              data?.INRDetails?.BxiCommisionGST
            )?.toFixed(2)
          : (
              data?.INRDetails?.totalBxiCommission +
              data?.INRDetails?.bxiCommisionGST
            )?.toFixed(2),
        // TDSOnCommission: data?.INRDetails?.TDS?.toFixed(2),
        // [`TotalTDS (${storeTDS?.TDSData?.TDS}%)`]:
        "TotalTDS(0.1%)": data?.INRDetails?.TDS?.toFixed(2),
        "FromBuyerToSeller(Coin)": (
          (TotalAdditionalCostWtihoutGSTInBXI || 0) +
          (TotalPriceWithoutGST || 0) -
          data?.INRDetails?.TDS
        ).toFixed(2),
        "FromBuyerToSeller(Inr)": data?.INRDetails?.TotalInrToPay?.toFixed(2),
        "FromSellertoBXI(Inr)": data?.IsMedia
          ? (
              data?.INRDetails?.TotalBxiCommission +
              data?.INRDetails?.BxiCommisionGST +
              data?.INRDetails?.TDS
            )?.toFixed(2)
          : (
              data?.INRDetails?.totalBxiCommission +
              data?.INRDetails?.bxiCommisionGST +
              data?.INRDetails?.TDS
            )?.toFixed(2),
        TotalInvoiceValue: (
          (TotalAdditionalCostWtihoutGSTInBXI || 0) +
          (TotalPriceWithoutGST || 0) -
          (data?.INRDetails?.TDS || 0) +
          data?.INRDetails?.TotalInrToPay
        ).toFixed(2),
      };
    });
  };

  const handleDownloadExcel = async () => {
    try {
      if (
        endDate === null ||
        startDate === null ||
        startDate?.$d == "Invalid Date" ||
        endDate?.$d == "Invalid Date"
      ) {
        alert("Please select a date.");
      } else {
        if (excelData !== null) {
          const flattenedData = await flattenUserData(excelData);

          // Ensure numeric values are properly formatted
          const formattedData = flattenedData.map((item) => {
            const newItem = { ...item };
            for (const key in newItem) {
              if (!isNaN(newItem[key]) && newItem[key] !== "") {
                newItem[key] = Number(newItem[key]);
              }
            }
            return newItem;
          });

          const workbook = XLSX.utils.book_new();
          const worksheet = XLSX.utils.json_to_sheet(formattedData);

          XLSX.utils.book_append_sheet(workbook, worksheet, "UserData");

          const outputFile = "Financial_Data.xlsx";
          XLSX.writeFile(workbook, outputFile);
        } else {
          setExcelLoading(true);
        }
      }
    } catch (error) {}
  };
  const handleDownloadrefundExcel = async () => {
    try {
      if (
        refundendDate === null ||
        refundstartDate === null ||
        refundstartDate?.$d == "Invalid Date" ||
        refundendDate?.$d == "Invalid Date"
      ) {
        alert("Please select a date.");
      } else {
        if (refundexcelData !== null) {
          const flattenedData = await RefundflattenUserData(refundexcelData);

          // Ensure numeric values are properly formatted
          const formattedData = flattenedData.map((item) => {
            const newItem = { ...item };
            for (const key in newItem) {
              if (!isNaN(newItem[key]) && newItem[key] !== "") {
                newItem[key] = Number(newItem[key]);
              }
            }
            return newItem;
          });

          const workbook = XLSX.utils.book_new();
          const worksheet = XLSX.utils.json_to_sheet(formattedData);

          XLSX.utils.book_append_sheet(workbook, worksheet, "UserData");

          const outputFile = "Refund_Data.xlsx";
          XLSX.writeFile(workbook, outputFile);
        } else {
          setrefundExcelLoading(true);
        }
      }
    } catch (error) {}
  };

  const [creditLineDetails, setCreditLineDetails] = useState([]);

  const GetAllCreditDetails = async () => {
    const Wallet_Url = "https://wallet-api.production-bxi.unada.in";
    // const Wallet_Url = "https://wallet-api.development-bxi.unada.in";

    await axios
      .get(`${Wallet_Url}/api/v1/chart/GetAllBxiCreditDetails`, {
        headers: {
          authorization: `Bearer ${GetTokenFunc()}`,
        },
      })
      .then((res) => {
        setCreditLineDetails(res.data);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    GetAllCreditDetails();
  }, []);

  const creditAcceptData = creditLineDetails?.creditAcceptData?.reduce(
    (acc, product) => {
      return acc + (product?._balance || 0);
    },
    0
  );

  return (
    <Paper
      sx={{
        width: "100%",
        bgcolor: "transparent",
        maxHeight: "90vh",
        overflow: "scroll",
      }}
      elevation={0}
    >
      <Grid container>
        <Grid
          item
          xl={3}
          lg={3}
          md={3}
          sm={12}
          xs={12}
          sx={{ bgcolor: "transparent" }}
        >
          <Box sx={{ ...RecenteUsersBox, borderTop: "4px solid #445FD2" }}>
            <Box sx={HeadingStyle}>
              <Typography sx={TokenText}>Product Listing</Typography>
            </Box>
            <Box sx={CountText}>
              <Typography sx={SubText}>
                Total Products :
                <span style={{ fontSize: "12px" }}>
                  {ProductCounts?.TotalProducts}
                </span>
              </Typography>
              <Typography sx={SubText}>
                Approved :
                <span style={{ fontSize: "12px" }}>
                  {ProductCounts?.Approved}
                </span>
              </Typography>
              <Typography sx={SubText}>
                Pending :
                <span style={{ fontSize: "12px" }}>
                  {ProductCounts?.Pending}
                </span>
              </Typography>
              <Typography sx={SubText}>
                Delist :
                <span style={{ fontSize: "12px" }}>
                  {ProductCounts?.Delist}
                </span>
              </Typography>
              <Typography sx={SubText}>
                Review :
                <span style={{ fontSize: "12px" }}>
                  {ProductCounts?.Review}
                </span>
              </Typography>
              <Typography sx={SubText}>
                Indraft :
                <span style={{ fontSize: "12px" }}>
                  {ProductCounts?.Indraft}
                </span>
              </Typography>
              <Typography sx={SubText}>
                Rejected :
                <span style={{ fontSize: "12px" }}>
                  {ProductCounts?.Rejected}
                </span>
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xl={3}
          lg={3}
          md={3}
          sm={12}
          xs={12}
          sx={{ bgcolor: "transparent" }}
        >
          <Box sx={{ ...RecenteUsersBox, borderTop: "4px solid #445FD2" }}>
            <Box sx={HeadingStyle}>
              <Typography sx={TokenText}>Companies</Typography>
            </Box>
            <Box sx={CountText}>
              <Typography sx={SubText}>
                Email Verification :
                <span style={{ fontSize: "11px" }}>{CompanyCounts?.Email}</span>
              </Typography>
              <Typography sx={SubText}>
                GST Details :
                <span style={{ fontSize: "11px" }}>{CompanyCounts?.GST}</span>
              </Typography>
              <Typography sx={SubText}>
                Bank Details :
                <span style={{ fontSize: "11px" }}>
                  {CompanyCounts?.BANK_DETAILS}
                </span>
              </Typography>
              <Typography sx={SubText}>
                T&C :
                <span style={{ fontSize: "11px" }}>{CompanyCounts?.TNC}</span>
              </Typography>
              <Typography sx={SubText}>
                Membership Plans :
                <span style={{ fontSize: "11px" }}>
                  {CompanyCounts?.PAYMENT}
                </span>
              </Typography>
              <Typography sx={SubText}>
                Under Review :
                <span style={{ fontSize: "11px" }}>
                  {CompanyCounts?.UNDER_REVIEW}
                </span>
              </Typography>
              <Typography sx={SubText}>
                Onboarding Complete :
                <span style={{ fontSize: "11px" }}>
                  {CompanyCounts?.ONBOARDING_COMPLETE}
                </span>
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xl={3}
          lg={3}
          md={3}
          sm={12}
          xs={12}
          sx={{ bgcolor: "transparent" }}
        >
          <Box sx={{ ...RecenteUsersBox, borderTop: "4px solid #445FD2" }}>
            <Box sx={HeadingStyle}>
              <Typography sx={TokenText}>Members</Typography>
            </Box>
            <Box sx={CountText}>
              <Typography sx={SubText}>
                Total members :
                <span style={{ fontSize: "12px" }}>
                  {MemberShipCounts?.Allplan}
                </span>
              </Typography>
              <Typography sx={SubText}>
                Advanced Plan Members :
                <span style={{ fontSize: "12px" }}>
                  {MemberShipCounts?.AllPlatinumPlan}
                </span>
              </Typography>
              <Typography sx={SubText}>
                Standard Plan Members :
                <span style={{ fontSize: "12px" }}>
                  {MemberShipCounts?.AllBasicPlan}
                </span>
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xl={3}
          lg={3}
          md={3}
          sm={12}
          xs={12}
          sx={{ bgcolor: "transparent" }}
        >
          <Box sx={{ ...RecenteUsersBox, borderTop: "4px solid #445FD2" }}>
            <Box sx={HeadingStyle}>
              <Typography sx={TokenText}>Credit Line</Typography>
            </Box>
            <Box sx={CountText}>
              <Typography sx={SubText}>
                Credit Line Approved:
                <span style={{ fontSize: "12px" }}>
                  <CommaSeprator Price={creditAcceptData} />{" "}
                  <span>
                    {" "}
                    <img
                      src={BXICOIN}
                      alt=""
                      style={{
                        width: "12px",
                        height: "auto",
                      }}
                    />
                  </span>
                </span>
              </Typography>
              <Typography sx={SubText}>
                Recovery due/pending :
                <span style={{ fontSize: "12px" }}>
                  <CommaSeprator Price={AllCreditdue} />{" "}
                  <span>
                    {" "}
                    <img
                      src={BXICOIN}
                      alt=""
                      style={{
                        width: "12px",
                        height: "auto",
                      }}
                    />
                  </span>
                </span>
              </Typography>
              <Typography sx={SubText}>
                Total Default Amount :
                <span style={{ fontSize: "12px" }}>
                  <CommaSeprator
                    Price={CreditIssueCounts?.totalDefaultAmount}
                  />{" "}
                  <span>
                    {" "}
                    <img
                      src={BXICOIN}
                      alt=""
                      style={{
                        width: "12px",
                        height: "auto",
                      }}
                    />
                  </span>
                </span>
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Grid container>
        <Grid
          item
          xl={3}
          lg={3}
          md={3}
          sm={12}
          xs={12}
          sx={{ bgcolor: "transparent" }}
        >
          <Box
            sx={{ ...RecenteUsersBox, position: "relative", height: "200px" }}
          >
            <Box
              component="img"
              src={InventoryBox}
              sx={{
                position: "absolute",
                bottom: "0%",
                right: "0%",
                width: "100px",
                height: "100px",
              }}
            />
            <Box sx={HeadingStyle}>
              <Typography sx={TokenText}>Platform Inventory</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                height: "70%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography sx={TokenAmountStyle}>
                ₹ <CommaSeprator Price={TotalAmount?.totalAmount} />
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xl={9}
          lg={9}
          md={9}
          sm={12}
          xs={12}
          sx={{ bgcolor: "transparent" }}
        >
          <Box
            sx={{
              ...RecenteUsersBox,
              height: "200px",
              width: "96%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box sx={HeadingStyle}>
              <Typography sx={TokenText}>
                To change the Marketplace Banner
              </Typography>
            </Box>
            <Button
              onClick={() => {
                navigate("/admindashboard/banner");
              }}
              sx={{
                ...ButtonStyleForUpload,
                maxWidth: "150px",
                marginTop: "10px",
              }}
            >
              Update Banner
            </Button>
          </Box>
        </Grid>

        <Grid
          item
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          sx={{ bgcolor: "transparent" }}
        >
          <Box
            sx={{
              height: "auto",
              minHeight: "150px",
              background: "#ffffff",
              border: "0.5px solid #848484",
              borderRadius: "0px 0px 8.5px 8.5px",
              mx: "auto",
              mt: 3,
              width: "97%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                width: "50%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box sx={HeadingStyle}>
                <Typography sx={TokenText}>Financial Data Download</Typography>
              </Box>
              <Box>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateRangePicker
                    startText="Start-Date"
                    endText="End-Date"
                    value={[startDate, endDate]}
                    onChange={handleDateRangeChange}
                    renderInput={(startProps, endProps) => (
                      <>
                        <input {...startProps} />
                        <input {...endProps} />
                      </>
                    )}
                  />
                </LocalizationProvider>
              </Box>

              <Button
                // variant="contained"
                // color="primary"
                onClick={() => {
                  if (excelLoading) {
                    return;
                  } else {
                    handleDownloadExcel();
                  }
                }}
                sx={{
                  ...ButtonStyleForUpload,
                  maxWidth: "150px",
                  marginTop: "10px",
                }}
              >
                {excelLoading ? "Loading..." : "Download"}
              </Button>
            </Box>
            <Box
              sx={{
                width: "50%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box sx={HeadingStyle}>
                <Typography sx={TokenText}>Refund Data Download</Typography>
              </Box>
              <Box>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateRangePicker
                    startText="Start-Date"
                    endText="End-Date"
                    value={[refundstartDate, refundendDate]}
                    onChange={handleRefundDateRangeChange}
                    renderInput={(startProps, endProps) => (
                      <>
                        <input {...startProps} />
                        <input {...endProps} />
                      </>
                    )}
                  />
                </LocalizationProvider>
              </Box>

              <Button
                // variant="contained"
                // color="primary"
                onClick={() => {
                  if (refundexcelLoading) {
                    return;
                  } else {
                    handleDownloadrefundExcel();
                  }
                }}
                sx={{
                  ...ButtonStyleForUpload,
                  maxWidth: "150px",
                  marginTop: "10px",
                }}
              >
                {refundexcelLoading ? "Loading..." : "Download"}
              </Button>
            </Box>
          </Box>
        </Grid>

        <Grid
          item
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          sx={{ bgcolor: "transparent" }}
        >
          <Box
            sx={{
              height: "auto",
              minHeight: "300px",
              background: "#ffffff",
              border: "0.5px solid #848484",
              borderRadius: "0px 0px 8.5px 8.5px",
              mx: "auto",
              mt: 3,
              width: "97%",
            }}
          >
            <Box
              sx={{
                ...HeadingStyle,
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              <Typography
                sx={{ ...TokenText, width: "98%", textAlign: "center" }}
              >
                Membership Update
              </Typography>
              <Edit
                onClick={() => {
                  setIsDisabled(!isDisabled);
                }}
                style={{
                  width: "2%",
                  color: "rgba(68, 95, 210, 1)",
                  cursor: "pointer",
                }}
              />
            </Box>

            <Grid
              container
              sx={{
                ...HeadingStyle,
                flexDirection: "row",
                justifyContent: "space-between",
                py: 2,
              }}
            >
              <Grid
                item
                xl={6}
                lg={6}
                md={12}
                sm={12}
                xs={12}
                sx={{
                  height: "auto",
                  borderRight: "1px solid #D4D4D4",
                }}
              >
                <Box
                  className="single-row"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Box>
                    <Typography sx={{ color: "#6B7A99" }}>
                      Plan Type :
                    </Typography>
                    <input
                      type="text"
                      variant="outlined"
                      value={MemberShipData?.BasicPlanType}
                      onChange={handleInputChange}
                      name="BasicPlanType"
                      style={inputstyle}
                      disabled={true}
                      className={true && "disabled"}
                      //   onChange={handleInputChange}
                    />
                  </Box>
                  <Box>
                    <Typography sx={{ color: "#6B7A99" }}>
                      Plan Name :
                    </Typography>
                    <input
                      type="text"
                      variant="outlined"
                      value={MemberShipData?.BasicPlanName}
                      onChange={handleInputChange}
                      name="BasicPlanName"
                      style={inputstyle}
                      disabled={isDisabled}
                      className={isDisabled && "disabled"}
                      //   onChange={handleInputChange}
                    />
                  </Box>
                  <Box
                    sx={{
                      position: "relative",
                    }}
                  >
                    <Typography sx={{ color: "#6B7A99" }}>
                      Plan Price :
                    </Typography>
                    <input
                      type="text"
                      variant="outlined"
                      value={MemberShipData?.BasicPlanPrice}
                      onChange={handleInputChange}
                      name="BasicPlanPrice"
                      style={inputstyle}
                      disabled={isDisabled}
                      className={isDisabled && "disabled"}
                      //   onChange={handleInputChange}
                    />
                    <span
                      style={{
                        position: "absolute",
                        right: "5%",
                        color: "#808080",
                      }}
                    >
                      ₹
                    </span>
                  </Box>
                  {/* <Box>
                    <Typography sx={{ color: "#6B7A99" }}>TDS :</Typography>
                    <input
                      type="text"
                      variant="outlined"
                      value={MemberShipData?.BasicTDS}
                      onChange={handleInputChange}
                      name="BasicTDS"
                      style={inputstyle}
                      disabled={isDisabled}
                      className={isDisabled && "disabled"}
                      //   onChange={handleInputChange}
                    />
                  </Box> */}
                  <Box
                    sx={{
                      width: "83.5%",
                      position: "relative",
                    }}
                  >
                    <Typography sx={{ color: "#6B7A99" }}>
                      Brokerage :
                    </Typography>
                    <input
                      type="text"
                      variant="outlined"
                      value={MemberShipData?.BasicGST}
                      onChange={handleInputChange}
                      name="BasicGST"
                      style={{ ...inputstyle, width: "150px" }}
                      disabled={isDisabled}
                      className={isDisabled && "disabled"}
                      //   onChange={handleInputChange}
                    />
                    <span
                      style={{
                        position: "absolute",
                        left: "55%",
                        color: "#808080",
                      }}
                    >
                      %
                    </span>
                  </Box>
                  <Box
                    sx={{
                      width: "83.5%",
                    }}
                  >
                    <Typography sx={{ color: "#6B7A99" }}>
                      Is Active :
                    </Typography>
                    <FormControlLabel
                      control={
                        <IOSSwitch
                          sx={{ m: 1, ml: 2.5 }}
                          checked={MemberShipData?.BasicisActive}
                          onChange={(e) =>
                            handleSwitchChange(e, "BasicisActive")
                          }
                          name="BasicisActive"
                          disabled={isDisabled}
                          className={isDisabled && "disabled"}
                        />
                      }
                    />
                  </Box>
                </Box>
                {isDisabled ? null : (
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      variant="contained"
                      sx={planbtn}
                      onClick={() => {
                        handleSubmitBasic(MemberShipData?.BasicPlanId);
                      }}
                    >
                      Update Membership
                    </Button>
                  </Box>
                )}
              </Grid>
              <Grid
                item
                xl={6}
                lg={6}
                md={12}
                sm={12}
                xs={12}
                sx={{
                  height: "auto",
                  mx: "auto",
                }}
              >
                <Box
                  className="single-row"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Box>
                    <Typography sx={{ color: "#6B7A99" }}>
                      Plan Type :
                    </Typography>
                    <input
                      type="text"
                      variant="outlined"
                      value={MemberShipData?.AdvancedPlanType}
                      onChange={handleInputChange}
                      name="AdvancedPlanType"
                      style={inputstyle}
                      disabled={true}
                      className={true && "disabled"}
                      //   onChange={handleInputChange}
                    />
                  </Box>
                  <Box>
                    <Typography sx={{ color: "#6B7A99" }}>
                      Plan Name :
                    </Typography>
                    <input
                      type="text"
                      variant="outlined"
                      value={MemberShipData?.AdvancedPlanName}
                      onChange={handleInputChange}
                      name="AdvancedPlanName"
                      style={inputstyle}
                      disabled={isDisabled}
                      className={isDisabled && "disabled"}
                      //   onChange={handleInputChange}
                    />
                  </Box>
                  <Box
                    sx={{
                      position: "relative",
                    }}
                  >
                    <Typography sx={{ color: "#6B7A99" }}>
                      Plan Price :
                    </Typography>
                    <input
                      type="text"
                      variant="outlined"
                      value={MemberShipData?.AdvancedPlanPrice}
                      onChange={handleInputChange}
                      name="AdvancedPlanPrice"
                      style={inputstyle}
                      disabled={isDisabled}
                      className={isDisabled && "disabled"}
                      //   onChange={handleInputChange}
                    />
                    <span
                      style={{
                        position: "absolute",
                        right: "5%",
                        color: "#808080",
                      }}
                    >
                      ₹
                    </span>
                  </Box>
                  {/* <Box>
                    <Typography sx={{ color: "#6B7A99" }}>TDS :</Typography>
                    <input
                      type="text"
                      variant="outlined"
                      value={MemberShipData?.AdvancedTDS}
                      onChange={handleInputChange}
                      name="AdvancedTDS"
                      style={inputstyle}
                      disabled={isDisabled}
                      className={isDisabled && "disabled"}
                      //   onChange={handleInputChange}
                    />
                  </Box> */}
                  <Box
                    sx={{
                      width: "83.5%",
                      position: "relative",
                    }}
                  >
                    <Typography sx={{ color: "#6B7A99" }}>
                      Brokerage :
                    </Typography>
                    <input
                      type="text"
                      variant="outlined"
                      value={MemberShipData?.AdvancedGST}
                      onChange={handleInputChange}
                      name="AdvancedGST"
                      style={{ ...inputstyle, width: "150px" }}
                      disabled={isDisabled}
                      className={isDisabled && "disabled"}
                      //   onChange={handleInputChange}
                    />
                    <span
                      style={{
                        position: "absolute",
                        left: "55%",
                        color: "#808080",
                      }}
                    >
                      %
                    </span>
                  </Box>
                  <Box
                    sx={{
                      width: "83.5%",
                    }}
                  >
                    <Typography sx={{ color: "#6B7A99" }}>
                      Is Active :
                    </Typography>
                    <FormControlLabel
                      control={
                        <IOSSwitch
                          sx={{ m: 1, ml: 2.5 }}
                          checked={MemberShipData?.AdvancedisActive}
                          onChange={(e) =>
                            handleSwitchChange(e, "AdvancedisActive")
                          }
                          name="AdvancedisActive"
                          disabled={isDisabled}
                          className={isDisabled && "disabled"}
                        />
                      }
                    />
                  </Box>
                </Box>
                {isDisabled ? null : (
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      variant="contained"
                      sx={planbtn}
                      onClick={() => {
                        handleSubmitAdvanced(MemberShipData?.AdvancedPlanId);
                      }}
                    >
                      Update Membership
                    </Button>
                  </Box>
                )}
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Dashboard;

const RecenteUsersBox = {
  width: "90%",
  height: "300px",
  background: "#ffffff",
  border: "0.5px solid #848484",
  borderRadius: "0px 0px 8.5px 8.5px",
  mx: "auto",
  mt: 3,
};

const TokenAmountStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "24px",
  color: "#191919",
  display: "flex",
  alignItems: "center",
};

const HeadingStyle = {
  display: "flex",
  justifyContent: "center",
  width: "100%",
  p: 1,
};

const SubText = {
  color: "#6B7A99",
  fontFamily: "Poppins",
  fontSize: "12px",
  fontWeight: 400,
  display: "flex",
  justifyContent: "space-between",
};

const TokenText = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: { xl: "15px", lg: "15px", md: "15px", sm: "12px", xs: "12px" },
  color: "#393D5E",
};

const CountText = {
  width: "100%",
  height: "auto",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  gap: "10px",
  p: 2,
};

const ImageSelectBoxStyle = {
  border: "1px dashed #445FD2",
  borderRadius: "5px",
  background: "#fff",
  width: "95%",
  p: "1%",
  mx: "auto",
  position: "relative",
  mt: "1%",
};

const DisplayGridStyle = {
  display: "grid",
  justifyContent: "center",
  alignItems: "center",
  tetxAlign: "center",
};

const ImageBox = {
  display: "flex",
  justifyContent: "center",
  position: "absolute",
  left: "25%",
  top: "25%",
  textalign: "center",
};

const TextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "10px",
  textTransform: "none",
  color: "#6B7A99",
};

const ImageMapBox = {
  background: "#fff",
  border: "1px solid green",
  borderRadius: "9px",
  height: "42px",
  width: "auto",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  my: "10px",
  px: "8px",
};

const ButtonStyleForUpload = {
  width: "100%",
  height: "30px",
  maxWidth: "100px",
  borderRadius: "6px",
  bgcolor: "#2261A2",
  textTransform: "none",
  color: "#fff",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "14px",
  color: "#FFFFFF",
  textAlign: "center",
  alignContent: "center",
  alignItems: "center",
  justifyContent: "center",
  display: "flex",
  curso0r: "pointer",
  my: 1,
  border: "1px solid #fff",
  "&:hover": {
    color: "#2261A2",
    border: "1px solid #2261A2",
  },
};

const inputstyle = {
  border: "0.7px solid #7D8FB3B2",
};

const planbtn = {
  bgcolor: "#445FD2",
  boxShadow: "none",
  textTransform: "none",
  width: "175px",
  height: "30px",
  fontFamily: "Poppins",
  fontSize: "12px",
  fontWeight: 500,
  my: 2,
};
