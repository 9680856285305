import React, { useEffect, useState, useRef } from "react";
import Box from "@mui/material/Box";
import "../Company/Company.Modules.css";
import Pagination from "@mui/material/Pagination";
import { styled } from "@mui/material/styles";
import { useParams } from "react-router-dom";
import { Stack, Button, Typography, Paper } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import axios from "axios";
import { GetTokenFunc } from "../../utils/GetToken";
import { HiDownload } from "react-icons/hi";
import * as XLSX from "xlsx/xlsx.mjs";
import GetCompanyNameNProfile from "./GetCompanyNameNProfile.js";
import PageLoader from "../CommonActions/PageLoader.jsx";
import Searchbar from "../../common/Searchbar.jsx";
import TransactionsCommaSeprator from "./TransactionsCommaSeprator.jsx";
import dayjs from "dayjs";

function Wallettransaction() {
  const StyledPagination = styled(Pagination)({
    "& .MuiPagination-ul li:last-child": {
      marginLeft: "16px",
    },
    "& .MuiPagination-ul li:last-child button::before": {
      content: "'Last'",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "11px",
      lineHeight: "14px",
      color: "#2261A2",
      marginRight: "8px",
    },
    "& .MuiPagination-ul li:first-child": {
      marginRight: "16px",
    },
    "& .MuiPagination-ul li:first-child button::after": {
      content: "'First'",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "11px",
      lineHeight: "14px",
      color: "#2261A2",
      marginLeft: "8px",
    },
    "& .MuiPaginationItem-icon": {
      color: "#2261A2",
      fontWeight: 800,
    },
  });
  const { id } = useParams();
  const [Statement, setStatement] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [Loading, setLoading] = useState(false);
  const [user, setUser] = useState([]);
  const [userData, setuserData] = useState();
  const [StatementNoLimit, setStatementNoLimit] = useState([]);
  const [initialPaginationCount, setInitialPaginationCount] = useState(
    currentPage || 1
  );
  const [completedEntries, setCompletedEntries] = useState(true);
  const [PendingStatement, setPendingStatement] = useState([]);

  const [searchData, setSearchData] = useState("");

  const [storeFinancialDate, setStoreFinancialDate] = useState({
    startDate: 2021,
    endDate: new Date().getUTCFullYear(),
  });
  const [selectFromDate, setSelectFromDate] = useState(dayjs("2021-01-01"));
  const [selectToDate, setSelectToDate] = useState(dayjs());

  // Callback function to receive data from child component
  const handleChildData = (dataFromChild) => {
    // Handle data received from child component
    setSearchData(dataFromChild);
  };

  const formatDateTime = (dateTimeString) => {
    const createdAtDateTime = new Date(dateTimeString);
    const timeFormatOptions = {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };
    const formattedTime = createdAtDateTime.toLocaleTimeString(
      "en-US",
      timeFormatOptions
    );

    return `${formattedTime}`;
  };

  const comoanyIdApi = async () => {
    try {
      await axios
        .get(`/api/v1/admin/GetAllCompanys`, {
          withCredentials: true,
          headers: {
            authorization: `Bearer ${GetTokenFunc()}`,
          },
        })
        .then((res) => {
          setUser(res?.data?.Companys);
        });
    } catch (error) {
      console.error(error);
    }
  };

  const profileData = async () => {
    await axios
      .get(`/api/v1/admin/GetCompanysById?CategoryId=${id}`, {
        headers: {
          authorization: `Bearer ${GetTokenFunc()}`,
        },
      })
      .then((res) => {
        setuserData(res?.data?.at(0));
      })
      .catch((err) => {});
  };

  useEffect(() => {
    profileData();
  }, [Statement]);

  const fetchWalletData = async () => {
    setLoading(true);
    try {
      await axios
        .post(
          `wallet/get_user_wallet_statement_by_companyId?page=${currentPage}`,
          {
            companyId: id,
            financialYear: storeFinancialDate ?? {},
            selectFromDate: selectFromDate ?? new Date("01/01/2021"),
            selectToDate: selectToDate ?? new Date(),
          }
        )
        .then((res) => {
          console.log("dshjfgysgdufgusdgf", res);
          setLoading(false);
          setStatement(res?.data?.body?.transactionsData);
          setPendingStatement(res?.data?.body?.pending_transactions);
          setInitialPaginationCount(res?.data?.body?.finalCount);
        })
        .catch((err) => {
          console.log("sdjfbhuysdgfgusd", err);
          setLoading(false);
        });
    } catch (error) {
      console.error(error);
    }
    try {
      await axios
        .post(`wallet/get_user_wallet_noLimit_statement`, {
          companyId: id,
        })
        .then((res) => {
          setStatementNoLimit(res?.data?.filteredWalletData);
        })
        .catch((err) => {});
    } catch (error) {
      console.error(error);
    }
  };

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    fetchWalletData();
    comoanyIdApi();
  }, [currentPage, searchData]);

  useEffect(() => {
    fetchWalletData();
  }, []);

  useEffect(() => {
    setCurrentPage(1);
  }, [searchData]);

  function convertDate(inputFormat) {
    function pad(s) {
      return s < 10 ? "0" + s : s;
    }
    var d = new Date(inputFormat);
    return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join("/");
  }

  return (
    <Paper
      sx={{
        width: "100%",
        bgcolor: "transparent",
        maxHeight: "90vh",
        overflow: "scroll",
      }}
      elevation={0}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          maxHeight: "50px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            backgroundColor: "#f9f9f9",
            gap: 5,
          }}
        >
          {/* Completed Entries Button */}
          <Box
            sx={{
              padding: "10px 40px",
              border: completedEntries
                ? "1px solid #2261A2"
                : "1px solid #6B7A99",
              backgroundColor: completedEntries ? "#2261A2" : "transparent",
              color: completedEntries ? "#FFFFFF" : "#6B7A99",
              cursor: "pointer",
              borderRadius: "5px",
              textAlign: "center",
              fontFamily: "Poppins",
              fontWeight: 500,
              fontSize: "13px",
            }}
            onClick={() => setCompletedEntries(!completedEntries)}
          >
            Completed Entries
          </Box>

          {/* Pending Entries Button */}
          <Box
            sx={{
              padding: "10px 40px",
              border: !completedEntries
                ? "1px solid #2261A2"
                : "1px solid #6B7A99",
              backgroundColor: !completedEntries ? "#2261A2" : "transparent",
              color: !completedEntries ? "#FFFFFF" : "#6B7A99",
              cursor: "pointer",
              borderRadius: "5px",
              textAlign: "center",
              fontFamily: "Poppins",
              fontWeight: 500,
              fontSize: "13px",
            }}
            onClick={() => setCompletedEntries(!completedEntries)}
          >
            Pending Entries
          </Box>
        </Box>
        <Searchbar onSendData={handleChildData} />
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <GeneratePDFStatement
            StatementData={StatementNoLimit?.reverse()}
            UserData={userData}
          />
        </div>
      </Box>
      <Box
        sx={{
          width: "100%",
          maxWidth: "80vw",
          minWidth: "80vw",
          height: "auto",
        }}
      >
        <Paper
          elevation={0}
          sx={{
            width: "100%",
          }}
        >
          {Loading ? (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                mt: 20,
              }}
            >
              <PageLoader />
            </Box>
          ) : (
            <TableContainer sx={TableContainerStyle}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Typography sx={TableTitleStyle}>No</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={TableTitleStyle}>Date</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={TableTitleStyle}>Company Name</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={TableTitleStyle}>Order Number</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={TableTitleStyle}>Type</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={TableTitleStyle}>Debit</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={TableTitleStyle}>Credit</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={TableTitleStyle}>TDS</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={TableTitleStyle}>
                        Account Balance
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={TableTitleStyle}>
                        Credit Due Balance
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={TableTitleStyle}>
                        Wallet Usable Balance
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={TableTitleStyle}>Other</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={TableTitleStyle}>
                        BXI Commission
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={TableTitleStyle}>
                        Assessable Value
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={TableTitleStyle}>GST</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={TableTitleStyle}>
                        Total Invoice Value
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={TableTitleStyle}>TDS</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={TableTitleStyle}>
                        Net Receivable/(Payable)
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={TableTitleStyle}>INR</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={TableTitleStyle}>Coins</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={TableTitleStyle}>TDS (26AS)</Typography>
                    </TableCell>
                    <TableCell style={{ width: "300px" }}>
                      <Typography sx={TableTitleStyle}>
                        Invoice Value
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                {completedEntries ? (
                  <TableBody>
                    {Statement?.map((row, index) => {
                      let price;
                      if (
                        row._from_wallet_id === "admin" ||
                        row._from_wallet_id === userData?._id
                      ) {
                        price = row._from_wallet_balance;
                      } else {
                        price = row._to_wallet_balance;
                      }

                      const getTotalGST = (row) => {
                        if (
                          row?._from_wallet_id !== "admin" ||
                          row?._type === "add_coupon" ||
                          row?._type === "sell_coupon"
                        ) {
                          if (
                            row?.OrderData?.BuyerChoosedTransportation
                              ?.TransportationType === "Seller"
                          ) {
                            return (
                              (row?.INRDetails?.totalTransportationCostGST ??
                                0) +
                              (row?.INRDetails?.bxiCommisionGST ?? 0) +
                              (row?.INRDetails?.totalGstAmount ?? 0) +
                              (row?.INRDetails?.InsuranceCostGstValue ?? 0) +
                              (row?.INRDetails
                                ?.bxiCommisionOnTransportationGST ?? 0) +
                              (row?.INRDetails?.totalGstInrOfAdCost ?? 0) +
                              (row?.INRDetails?.packagingCostGSTValue ?? 0)
                            );
                          } else if (row?.OrderData?.IsMedia) {
                            return (
                              row?.INRDetails?.BxiCommisionGST +
                              row?.INRDetails?.TotalGstAmount +
                              row?.INRDetails?.TotalGstInrOfAdCost
                            );
                          } else {
                            return (
                              (row?.INRDetails?.bxiCommisionGST ?? 0) +
                              (row?.INRDetails?.totalGstAmount ?? 0) +
                              (row?.INRDetails?.totalGstInrOfAdCost ?? 0)
                            );
                          }
                        } else {
                          return null;
                        }
                      };

                      let Amount = row?._balance + row?.INRDetails?.TDS;

                      const getTotalBalance = (row) => {
                        if (
                          row?.OrderData?.BuyerChoosedTransportation
                            ?.TransportationType === "Seller"
                        ) {
                          return (
                            (Amount ?? 0) +
                            (row?.INRDetails?.totalTransportationCost ?? 0) +
                            (row?.INRDetails?.totalValueInrOfAdCost ?? 0) +
                            (row?.INRDetails?.InsuranceCost ?? 0) +
                            (row?.INRDetails?.packagingCost ?? 0) +
                            (row?.INRDetails?.totalBxiCommission ?? 0)
                          );
                        } else if (row?.OrderData?.IsMedia) {
                          return (
                            (Amount ?? 0) +
                            (row?.INRDetails?.TotalValueInrOfAdCost ?? 0) +
                            (row?.INRDetails?.TotalBxiCommission ?? 0)
                          );
                        } else {
                          return (
                            (Amount ?? 0) +
                            (row?.INRDetails?.totalValueInrOfAdCost ?? 0) +
                            (row?.INRDetails?.totalBxiCommission ?? 0)
                          );
                        }
                      };

                      return (
                        <TableRow key={index}>
                          <TableCell>
                            <Typography
                              sx={{
                                ...TextStyleCommon,
                                color: "black",
                              }}
                            >
                              {index + 1}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              sx={{
                                ...TextStyleCommon,
                                color: "black",
                              }}
                            >
                              {row._from_wallet_id === userData?._id?.toString()
                                ? convertDate(row?._created_at)
                                : convertDate(row?._release_date)}
                              <br />
                              at{" "}
                              {row._from_wallet_id === userData?._id
                                ? formatDateTime(row?._created_at)
                                : formatDateTime(row?._release_date)}
                            </Typography>
                          </TableCell>
                          {/* company profile */}
                          <TableCell>
                            <Typography
                              sx={{
                                ...TextStyleCommon,
                                color: "black",
                              }}
                            >
                              <GetCompanyNameNProfile
                                CompanyId={
                                  row?._from_wallet_id ===
                                  userData?._id?.toString()
                                    ? row?._to_wallet_id
                                    : row?._from_wallet_id
                                }
                              />
                            </Typography>
                          </TableCell>
                          {/* order number */}
                          <TableCell>
                            <Typography
                              sx={{
                                ...TextStyleCommon,
                                color: "black",
                              }}
                            >
                              {row?._order_number?.includes("BXI")
                                ? row?._order_number
                                : row?._clnumber || row?._order_number}
                            </Typography>
                          </TableCell>
                          {/* Type */}
                          <TableCell>
                            <Typography
                              sx={{
                                ...TextStyleCommon,
                                color: "black",
                              }}
                            >
                              {row?._type === "sell_recover_coupon"
                                ? "Sell/PrivateDeal"
                                : row?._type === "recover_coupon"
                                ? "Buy/PrivateDeal"
                                : row?._type === "sell_coupon" &&
                                  row?._from_wallet_id !==
                                    userData?._id?.toString()
                                ? "Sell/PrivateDeal"
                                : row?._type === "sell_coupon"
                                ? "Buy/PrivateDeal"
                                : row?._type === "add_coupon"
                                ? "Buy/PrivateDeal"
                                : row?._type === "refund"
                                ? "Refund"
                                : row?._type?.includes("credit_line")
                                ? "Credit Line"
                                : row?._from_wallet_id ===
                                  userData?._id?.toString()
                                ? "Buy"
                                : "Sell"}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              sx={{
                                ...TextStyleCommon,
                                color: "black",
                              }}
                            >
                              <CommaSeparator
                                Price={
                                  row?._from_wallet_id ===
                                    userData?._id?.toString() &&
                                  row?._type === "refund"
                                    ? null
                                    : row?._from_wallet_id ===
                                      userData?._id?.toString()
                                    ? Amount
                                    : row?._type === "credit_line_recovery"
                                    ? Amount
                                    : null
                                }
                              />
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              sx={{
                                ...TextStyleCommon,
                                color: "black",
                              }}
                            >
                              <CommaSeparator
                                Price={
                                  row?._from_wallet_id ===
                                    userData?._id?.toString() &&
                                  row?._type === "refund"
                                    ? Amount
                                    : row?._from_wallet_id !==
                                      userData?._id?.toString()
                                    ? row?._type === "add_coupon"
                                      ? Amount
                                      : row?._from_wallet_id === "admin" &&
                                        row?._type === "sell_coupon"
                                      ? Amount
                                      : row?._from_wallet_id === "admin"
                                      ? null
                                      : Amount
                                    : null
                                }
                              />
                            </Typography>
                          </TableCell>

                          <TableCell>
                            <Typography
                              sx={{
                                ...TextStyleCommon,
                                color: "black",
                              }}
                            >
                              <CommaSeparator Price={row?.INRDetails?.TDS} />
                            </Typography>
                          </TableCell>
                          {/* account balance */}
                          <TableCell>
                            <Typography
                              sx={{
                                ...TextStyleCommon,
                                color: "black",
                              }}
                            >
                              {price < 0 ? (
                                <>
                                  -
                                  <CommaSeparator Price={Math.abs(price)} />
                                </>
                              ) : (
                                <CommaSeparator Price={price} />
                              )}
                            </Typography>
                          </TableCell>
                          {/* credit due */}
                          <TableCell>
                            <Typography
                              sx={{
                                ...TextStyleCommon,
                                color: "black",
                              }}
                            >
                              <CommaSeparator
                                Price={
                                  row?._type === "add_coupon"
                                    ? row?._from_wallet_credit_due
                                    : row?._from_wallet_id === "admin" &&
                                      row?._type === "sell_coupon"
                                    ? row?._from_wallet_credit_due?.toFixed(2)
                                    : row?._from_wallet_id === "admin"
                                    ? row?._to_wallet_credit_due?.toFixed(2)
                                    : row?._from_wallet_id ===
                                      userData?._id?.toString()
                                    ? row?._from_wallet_credit_due?.toFixed(2)
                                    : row?._to_wallet_credit_due?.toFixed(2)
                                }
                              />
                            </Typography>
                          </TableCell>
                          {/* Wallet Usable Balance */}
                          <TableCell>
                            <Typography
                              sx={{
                                ...TextStyleCommon,
                                color: "black",
                              }}
                            >
                              <CommaSeparator
                                Price={
                                  row?._type === "add_coupon"
                                    ? row?._from_wallet_usable_balance
                                    : row?._from_wallet_id === "admin" &&
                                      row?._type === "sell_coupon"
                                    ? row?._from_wallet_usable_balance?.toFixed(
                                        2
                                      )
                                    : row?._from_wallet_id === "admin"
                                    ? row?._to_wallet_usable_balance?.toFixed(2)
                                    : row?._from_wallet_id ===
                                      userData?._id?.toString()
                                    ? row?._from_wallet_usable_balance?.toFixed(
                                        2
                                      )
                                    : row?._to_wallet_usable_balance?.toFixed(2)
                                }
                              />
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              sx={{
                                ...TextStyleCommon,
                                color: "black",
                              }}
                            >
                              {row?.OrderData?.BuyerChoosedTransportation
                                ?.TransportationType === "Seller" ? (
                                <CommaSeparator
                                  Price={
                                    row?.INRDetails?.totalTransportationCost +
                                      row?.INRDetails?.totalValueInrOfAdCost +
                                      row?.INRDetails?.packagingCost +
                                      row?.INRDetails?.InsuranceCost || 0
                                  }
                                />
                              ) : (
                                <CommaSeparator
                                  Price={
                                    row?.INRDetails?.totalValueInrOfAdCost ||
                                    row?.INRDetails?.TotalValueInrOfAdCost ||
                                    0
                                  }
                                />
                              )}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              sx={{
                                ...TextStyleCommon,
                                color: "black",
                              }}
                            >
                              <CommaSeparator
                                Price={
                                  row?.INRDetails?.TotalBxiCommission ||
                                  row?.INRDetails?.totalBxiCommission ||
                                  0
                                }
                              />
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              sx={{
                                ...TextStyleCommon,
                                color: "black",
                              }}
                            >
                              <CommaSeparator Price={getTotalBalance(row)} />
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              sx={{
                                ...TextStyleCommon,
                                color: "black",
                              }}
                            >
                              <CommaSeparator Price={getTotalGST(row)} />
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              sx={{
                                ...TextStyleCommon,
                                color: "black",
                              }}
                            >
                              <CommaSeparator
                                Price={
                                  getTotalBalance(row) + getTotalGST(row)
                                    ? (
                                        getTotalBalance(row) + getTotalGST(row)
                                      )?.toFixed(2)
                                    : 0
                                }
                              />
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              sx={{
                                ...TextStyleCommon,
                                color: "black",
                              }}
                            >
                              <CommaSeparator Price={row?.INRDetails?.TDS} />
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              sx={{
                                ...TextStyleCommon,
                                color: "black",
                              }}
                            >
                              <CommaSeparator
                                Price={
                                  row?._from_wallet_id !==
                                  userData?._id?.toString()
                                    ? getTotalBalance(row) +
                                      getTotalGST(row) -
                                      row?.INRDetails?.TDS
                                    : getTotalBalance(row) + getTotalGST(row)
                                }
                              />
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              sx={{
                                ...TextStyleCommon,
                                color: "black",
                              }}
                            >
                              <CommaSeparator
                                Price={row?.INRDetails?.TotalInrToPay}
                              />
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              sx={{
                                ...TextStyleCommon,
                                color: "black",
                              }}
                            >
                              <CommaSeparator Price={row?._balance} />
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              sx={{
                                ...TextStyleCommon,
                                color: "black",
                              }}
                            >
                              <CommaSeparator
                                Price={
                                  row?._from_wallet_id !==
                                  userData?._id?.toString()
                                    ? row?.INRDetails?.TDS
                                    : null
                                }
                              />
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              sx={{
                                ...TextStyleCommon,
                                color: "black",
                              }}
                            >
                              <CommaSeparator
                                Price={
                                  getTotalBalance(row) + getTotalGST(row)
                                    ? (
                                        getTotalBalance(row) + getTotalGST(row)
                                      )?.toFixed(2)
                                    : 0
                                }
                              />
                            </Typography>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                ) : (
                  <TableBody>
                    {PendingStatement?.map((row, index) => {
                      const wallet_release =
                        Boolean(
                          row?.OrderData?.commissionJuspayResponse?.status ===
                            "CHARGED"
                        ) ||
                        row?._type === "refund" ||
                        row?._refunded ||
                        row?._from_wallet_id === "admin" ||
                        (row?._document_type === "delivery_confirmed" &&
                          row?._from_wallet_id === userData?.data?._id);

                      let price;
                      if (
                        row._from_wallet_id === "admin" ||
                        row._from_wallet_id === userData?.data?._id
                      ) {
                        price = row._from_wallet_balance;
                      } else {
                        price = row._to_wallet_balance;
                      }

                      const getTotalGST = (row) => {
                        if (
                          row?._from_wallet_id !== "admin" ||
                          row?._type === "add_coupon" ||
                          row?._type === "sell_coupon"
                        ) {
                          if (
                            row?.OrderData?.BuyerChoosedTransportation
                              ?.TransportationType === "Seller"
                          ) {
                            return (
                              (row?.INRDetails?.totalTransportationCostGST ??
                                0) +
                              (row?.INRDetails?.bxiCommisionGST ?? 0) +
                              (row?.INRDetails?.totalGstAmount ?? 0) +
                              (row?.INRDetails?.InsuranceCostGstValue ?? 0) +
                              (row?.INRDetails
                                ?.bxiCommisionOnTransportationGST ?? 0) +
                              (row?.INRDetails?.totalGstInrOfAdCost ?? 0) +
                              (row?.INRDetails?.packagingCostGSTValue ?? 0)
                            );
                          } else if (row?.OrderData?.IsMedia) {
                            return (
                              row?.INRDetails?.BxiCommisionGST +
                              row?.INRDetails?.TotalGstAmount +
                              row?.INRDetails?.TotalGstInrOfAdCost
                            );
                          } else {
                            return (
                              (row?.INRDetails?.bxiCommisionGST ?? 0) +
                              (row?.INRDetails?.totalGstAmount ?? 0) +
                              (row?.INRDetails?.totalGstInrOfAdCost ?? 0)
                            );
                          }
                        } else {
                          return null;
                        }
                      };

                      let Amount = row?._balance + row?.INRDetails?.TDS;

                      const getTotalBalance = (row) => {
                        if (
                          row?.OrderData?.BuyerChoosedTransportation
                            ?.TransportationType === "Seller"
                        ) {
                          return (
                            (Amount ?? 0) +
                            (row?.INRDetails?.totalTransportationCost ?? 0) +
                            (row?.INRDetails?.totalValueInrOfAdCost ?? 0) +
                            (row?.INRDetails?.InsuranceCost ?? 0) +
                            (row?.INRDetails?.packagingCost ?? 0) +
                            (row?.INRDetails?.totalBxiCommission ?? 0)
                          );
                        } else if (row?.OrderData?.IsMedia) {
                          return (
                            (Amount ?? 0) +
                            (row?.INRDetails?.TotalValueInrOfAdCost ?? 0) +
                            (row?.INRDetails?.TotalBxiCommission ?? 0)
                          );
                        } else {
                          return (
                            (Amount ?? 0) +
                            (row?.INRDetails?.totalValueInrOfAdCost ?? 0) +
                            (row?.INRDetails?.totalBxiCommission ?? 0)
                          );
                        }
                      };

                      return (
                        <TableRow key={index}>
                          <TableCell>
                            <Typography
                              sx={{
                                ...TextStyleCommon,
                                color: wallet_release ? "black" : "#FF8E00",
                              }}
                            >
                              {index + 1}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              sx={{
                                ...TextStyleCommon,
                                color: wallet_release ? "black" : "#FF8E00",
                              }}
                            >
                              {row._from_wallet_id === userData?.data?._id
                                ? convertDate(row?._created_at)
                                : convertDate(row?._release_date)}
                              <br />
                              at{" "}
                              {row._from_wallet_id === userData?.data?._id
                                ? formatDateTime(row?._created_at)
                                : formatDateTime(row?._release_date)}
                            </Typography>
                          </TableCell>
                          {/* company profile */}
                          <TableCell>
                            <Typography
                              sx={{
                                ...TextStyleCommon,
                                color: wallet_release ? "black" : "#FF8E00",
                              }}
                            >
                              <GetCompanyNameNProfile
                                CompanyId={
                                  row?._from_wallet_id === userData?.data?._id
                                    ? row?._to_wallet_id
                                    : row?._from_wallet_id
                                }
                              />
                            </Typography>
                          </TableCell>
                          {/* order number */}
                          <TableCell>
                            <Typography
                              sx={{
                                ...TextStyleCommon,
                                color: wallet_release ? "black" : "#FF8E00",
                              }}
                            >
                              {row?._order_number?.includes("BXI")
                                ? row?._order_number
                                : row?._clnumber || row?._order_number}
                            </Typography>
                          </TableCell>
                          {/* Type */}
                          <TableCell>
                            <Typography
                              sx={{
                                ...TextStyleCommon,
                                color: wallet_release ? "black" : "#FF8E00",
                              }}
                            >
                              {row?._type === "sell_recover_coupon"
                                ? "Sell/PrivateDeal"
                                : row?._type === "recover_coupon"
                                ? "Buy/PrivateDeal"
                                : row?._type === "sell_coupon" &&
                                  row?._from_wallet_id !== userData?.data?._id
                                ? "Sell/PrivateDeal"
                                : row?._type === "sell_coupon"
                                ? "Buy/PrivateDeal"
                                : row?._type === "add_coupon"
                                ? "Buy/PrivateDeal"
                                : row?._type === "refund"
                                ? "Refund"
                                : row?._type?.includes("credit_line")
                                ? "Credit Line"
                                : row?._from_wallet_id === userData?.data?._id
                                ? "Buy"
                                : "Sell"}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              sx={{
                                ...TextStyleCommon,
                                color: wallet_release ? "black" : "#FF8E00",
                              }}
                            >
                              <CommaSeparator
                                Price={
                                  row?._from_wallet_id ===
                                    userData?.data?._id &&
                                  row?._type === "refund"
                                    ? Amount
                                    : row?._from_wallet_id ===
                                      userData?.data?._id
                                    ? Amount
                                    : row?._type === "credit_line_recovery"
                                    ? Amount
                                    : row?._type === "refund"
                                    ? Amount
                                    : null
                                }
                              />
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              sx={{
                                ...TextStyleCommon,
                                color: wallet_release ? "black" : "#FF8E00",
                              }}
                            >
                              <CommaSeparator
                                Price={
                                  row?._from_wallet_id ===
                                    userData?.data?._id &&
                                  row?._type === "refund"
                                    ? Amount
                                    : row?._from_wallet_id !==
                                      userData?.data?._id
                                    ? row?._type === "add_coupon"
                                      ? Amount
                                      : row?._from_wallet_id === "admin" &&
                                        row?._type === "sell_coupon"
                                      ? Amount
                                      : row?._from_wallet_id === "admin"
                                      ? null
                                      : row?._type === "refund"
                                      ? null
                                      : Amount
                                    : null
                                }
                              />
                            </Typography>
                          </TableCell>

                          <TableCell>
                            <Typography
                              sx={{
                                ...TextStyleCommon,
                                color: wallet_release ? "black" : "#FF8E00",
                              }}
                            >
                              <CommaSeparator Price={row?.INRDetails?.TDS} />
                            </Typography>
                          </TableCell>
                          {/* account balance */}
                          <TableCell>
                            <Typography
                              sx={{
                                ...TextStyleCommon,
                                color: wallet_release ? "black" : "#FF8E00",
                              }}
                            >
                              {price < 0 ? (
                                <>
                                  -
                                  <CommaSeparator Price={Math.abs(price)} />
                                </>
                              ) : (
                                <CommaSeparator Price={price} />
                              )}
                            </Typography>
                          </TableCell>
                          {/* credit due */}
                          <TableCell>
                            <Typography
                              sx={{
                                ...TextStyleCommon,
                                color: wallet_release ? "black" : "#FF8E00",
                              }}
                            >
                              <CommaSeparator
                                Price={
                                  row?._type === "add_coupon"
                                    ? row?._from_wallet_credit_due
                                    : row?._from_wallet_id === "admin" &&
                                      row?._type === "sell_coupon"
                                    ? row?._from_wallet_credit_due?.toFixed(2)
                                    : row?._from_wallet_id === "admin"
                                    ? row?._to_wallet_credit_due?.toFixed(2)
                                    : row?._from_wallet_id ===
                                      userData?.data?._id
                                    ? row?._from_wallet_credit_due?.toFixed(2)
                                    : row?._to_wallet_credit_due?.toFixed(2)
                                }
                              />
                            </Typography>
                          </TableCell>
                          {/* Wallet Usable Balance */}
                          <TableCell>
                            <Typography
                              sx={{
                                ...TextStyleCommon,
                                color: wallet_release ? "black" : "#FF8E00",
                              }}
                            >
                              <CommaSeparator
                                Price={
                                  row?._type === "add_coupon"
                                    ? row?._from_wallet_usable_balance
                                    : row?._from_wallet_id === "admin" &&
                                      row?._type === "sell_coupon"
                                    ? row?._from_wallet_usable_balance?.toFixed(
                                        2
                                      )
                                    : row?._from_wallet_id === "admin"
                                    ? row?._to_wallet_usable_balance?.toFixed(2)
                                    : row?._from_wallet_id ===
                                      userData?.data?._id
                                    ? row?._from_wallet_usable_balance?.toFixed(
                                        2
                                      )
                                    : row?._to_wallet_usable_balance?.toFixed(2)
                                }
                              />
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              sx={{
                                ...TextStyleCommon,
                                color: wallet_release ? "black" : "#FF8E00",
                              }}
                            >
                              {row?.OrderData?.BuyerChoosedTransportation
                                ?.TransportationType === "Seller" ? (
                                <CommaSeparator
                                  Price={
                                    row?.INRDetails?.totalTransportationCost +
                                      row?.INRDetails?.totalValueInrOfAdCost +
                                      row?.INRDetails?.packagingCost +
                                      row?.INRDetails?.InsuranceCost || 0
                                  }
                                />
                              ) : (
                                <CommaSeparator
                                  Price={
                                    row?.INRDetails?.totalValueInrOfAdCost ||
                                    row?.INRDetails?.TotalValueInrOfAdCost ||
                                    0
                                  }
                                />
                              )}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              sx={{
                                ...TextStyleCommon,
                                color: wallet_release ? "black" : "#FF8E00",
                              }}
                            >
                              <CommaSeparator
                                Price={
                                  row?.INRDetails?.TotalBxiCommission ||
                                  row?.INRDetails?.totalBxiCommission ||
                                  0
                                }
                              />
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              sx={{
                                ...TextStyleCommon,
                                color: wallet_release ? "black" : "#FF8E00",
                              }}
                            >
                              <CommaSeparator Price={getTotalBalance(row)} />
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              sx={{
                                ...TextStyleCommon,
                                color: wallet_release ? "black" : "#FF8E00",
                              }}
                            >
                              <CommaSeparator Price={getTotalGST(row)} />
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              sx={{
                                ...TextStyleCommon,
                                color: wallet_release ? "black" : "#FF8E00",
                              }}
                            >
                              <CommaSeparator
                                Price={
                                  getTotalBalance(row) + getTotalGST(row)
                                    ? (
                                        getTotalBalance(row) + getTotalGST(row)
                                      )?.toFixed(2)
                                    : 0
                                }
                              />
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              sx={{
                                ...TextStyleCommon,
                                color: wallet_release ? "black" : "#FF8E00",
                              }}
                            >
                              <CommaSeparator Price={row?.INRDetails?.TDS} />
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              sx={{
                                ...TextStyleCommon,
                                color: wallet_release ? "black" : "#FF8E00",
                              }}
                            >
                              <CommaSeparator
                                Price={
                                  row?._from_wallet_id !== userData?.data?._id
                                    ? getTotalBalance(row) +
                                      getTotalGST(row) -
                                      row?.INRDetails?.TDS
                                    : getTotalBalance(row) + getTotalGST(row)
                                }
                              />
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              sx={{
                                ...TextStyleCommon,
                                color: wallet_release ? "black" : "#FF8E00",
                              }}
                            >
                              <CommaSeparator
                                Price={row?.INRDetails?.TotalInrToPay}
                              />
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              sx={{
                                ...TextStyleCommon,
                                color: wallet_release ? "black" : "#FF8E00",
                              }}
                            >
                              <CommaSeparator Price={row?._balance} />
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              sx={{
                                ...TextStyleCommon,
                                color: wallet_release ? "black" : "#FF8E00",
                              }}
                            >
                              <CommaSeparator
                                Price={
                                  row?._from_wallet_id !== userData?.data?._id
                                    ? row?.INRDetails?.TDS
                                    : null
                                }
                              />
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              sx={{
                                ...TextStyleCommon,
                                color: wallet_release ? "black" : "#FF8E00",
                              }}
                            >
                              <CommaSeparator
                                Price={
                                  getTotalBalance(row) + getTotalGST(row)
                                    ? (
                                        getTotalBalance(row) + getTotalGST(row)
                                      )?.toFixed(2)
                                    : 0
                                }
                              />
                            </Typography>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          )}
        </Paper>
      </Box>

      <Box sx={{ display: "flex", justifyContent: "center" }} mt={2}>
        <Stack spacing={2}>
          <StyledPagination
            size="medium"
            count={initialPaginationCount}
            color="primary"
            showFirstButton
            showLastButton
            page={currentPage}
            onChange={handlePageChange}
            strokeWidth={currentPage}
          />
        </Stack>
      </Box>
    </Paper>
  );
}

export default Wallettransaction;

const CommaSeparator = (props) => {
  const number = props?.Price;
  const roundedNumber = Number(number);
  const isValidNumber = !isNaN(roundedNumber);
  const formattedNumber = isValidNumber
    ? roundedNumber.toLocaleString("en-IN", {
        minimumFractionDigits: roundedNumber % 1 !== 0 ? 2 : 0,
        maximumFractionDigits: roundedNumber % 1 !== 0 ? 2 : 0,
      })
    : "";

  if (formattedNumber <= 0) {
    return "";
  } else if (!Number.isInteger(roundedNumber)) {
    return formattedNumber;
  } else {
    return formattedNumber + ".00";
  }
};

const GeneratePDFStatement = (props) => {
  let ExcelData = props?.StatementData;
  const convertDate = (date) => {
    return new Date(date);
  };

  const generatePDFStatement = async () => {
    async function getCompanyByName(props) {
      let datda = await axios
        .get(`company/get_company/${props}`)
        .then((res) => {
          return res?.data?.companyName;
        });

      return datda;
    }

    const wb = XLSX.utils.book_new();

    const ws = XLSX.utils.json_to_sheet(ExcelData);

    const headerRows = [
      [`Company Name: ${props?.UserData?.data?.at(0)?.companyName}`],
      [`Email: ${props?.UserData?.data?.at(0)?.email}`],
      [`Address: ${props?.UserData?.data?.at(0)?.RegisteredAddress}`],
      [`Statement Date: ${new Date().toDateString()}`],
      [],
      [
        "Sr No",
        "Date",
        "Company Name",
        "Order Number",
        "Type",
        "Debit",
        "Credit",
        "TDS",
        "Account Balance",
        "Credit Due Balance",
        "Wallet Usable Balance",
        "Other",
        "BXI Commission",
        "Assessable Value",
        "GST",
        "Total Invoice Value",
        "TDS",
        "Net Receivable/(Payable)",
        "INR",
        "Coins",
        "TDS (26AS)",
        "Invoice Value",
      ],
    ];

    const infoWs = XLSX.utils.aoa_to_sheet(headerRows);

    await Promise.all(
      ExcelData?.map(async (row, index) => {
        const getTotalGST = (row) => {
          if (
            row?._from_wallet_id !== "admin" ||
            row?._type === "add_coupon" ||
            row?._type === "sell_coupon"
          ) {
            if (
              row?.OrderData?.BuyerChoosedTransportation?.TransportationType ===
              "Seller"
            ) {
              return (
                (row?.INRDetails?.totalTransportationCostGST ?? 0) +
                (row?.INRDetails?.bxiCommisionGST ?? 0) +
                (row?.INRDetails?.totalGstAmount ?? 0) +
                (row?.INRDetails?.InsuranceCostGstValue ?? 0) +
                (row?.INRDetails?.bxiCommisionOnTransportationGST ?? 0) +
                (row?.INRDetails?.totalGstInrOfAdCost ?? 0) +
                (row?.INRDetails?.packagingCostGSTValue ?? 0)
              );
            } else if (row?.OrderData?.IsMedia) {
              return (
                row?.INRDetails?.BxiCommisionGST +
                row?.INRDetails?.TotalGstAmount +
                row?.INRDetails?.TotalGstInrOfAdCost
              );
            } else {
              return (
                (row?.INRDetails?.bxiCommisionGST ?? 0) +
                (row?.INRDetails?.totalGstAmount ?? 0) +
                (row?.INRDetails?.totalGstInrOfAdCost ?? 0)
              );
            }
          } else {
            return 0;
          }
        };

        let Amount = row?._balance + row?.INRDetails?.TDS;

        const getTotalBalance = (row) => {
          if (
            row?.OrderData?.BuyerChoosedTransportation?.TransportationType ===
            "Seller"
          ) {
            return (
              (Amount ?? 0) +
              (row?.INRDetails?.totalTransportationCost ?? 0) +
              (row?.INRDetails?.totalValueInrOfAdCost ?? 0) +
              (row?.INRDetails?.InsuranceCost ?? 0) +
              (row?.INRDetails?.packagingCost ?? 0) +
              (row?.INRDetails?.totalBxiCommission ?? 0)
            );
          } else if (row?.OrderData?.IsMedia) {
            return (
              (Amount ?? 0) +
              (row?.INRDetails?.TotalValueInrOfAdCost ?? 0) +
              (row?.INRDetails?.TotalBxiCommission ?? 0)
            );
          } else {
            return (
              (Amount ?? 0) +
              (row?.INRDetails?.totalValueInrOfAdCost ?? 0) +
              (row?.INRDetails?.totalBxiCommission ?? 0)
            );
          }
        };

        const otherBalance = (row) => {
          return row?.OrderData?.BuyerChoosedTransportation
            ?.TransportationType === "Seller"
            ? row?.INRDetails?.totalTransportationCost +
                row?.INRDetails?.totalValueInrOfAdCost +
                row?.INRDetails?.packagingCost +
                row?.INRDetails?.InsuranceCost || "-"
            : row?.INRDetails?.totalValueInrOfAdCost ||
                row?.INRDetails?.TotalValueInrOfAdCost ||
                0;
        };

        // let Amount = (row?._balance + row?.INRDetails?.TDS).toFixed(2);

        let companyNamessss =
          (await getCompanyByName(
            row?._from_wallet_id === props?.UserData?.data?.at(0)?._id
              ? row?._to_wallet_id
              : row?._from_wallet_id
          )) || "Barter Exchange of India";

        let _closing_balance = 0;

        switch (true) {
          case row?._from_wallet_id === props?.UserData?.data?.at(0)?._id:
            _closing_balance = row?._closing_balance_buyer?.toFixed(2);
            break;
          case row?._to_wallet_id !== props?.UserData?.data?.at(0)?._id:
            _closing_balance = row?._closing_balance_seller?.toFixed(2);
            break;
          default:
            _closing_balance = row?._closing_balance_buyer?.toFixed(2);
            break;
        }
        if (row?._type === "credit_line") {
          _closing_balance = row?._closing_balance_buyer?.toFixed(2);
        }

        const rowData = [
          // srno
          index + 1,
          // Date
          convertDate(row._created_at)?.toLocaleString("en-IN"),
          // compny name
          companyNamessss,
          // Order number
          row?._order_number?.includes("BXI")
            ? row?._order_number
            : row?._clnumber || row?._order_number,
          // type
          row?._type === "sell_recover_coupon"
            ? "Sell/PrivateDeal"
            : row?._type === "recover_coupon"
            ? "Buy/PrivateDeal"
            : row?._type === "sell_coupon" &&
              row?._from_wallet_id !== props?.UserData?.data?.at(0)?._id
            ? "Sell/PrivateDeal"
            : row?._type === "sell_coupon"
            ? "Buy/PrivateDeal"
            : row?._type === "add_coupon"
            ? "Buy/PrivateDeal"
            : row?.type === "refund"
            ? "Refund"
            : row?._type?.includes("credit_line")
            ? "Credit Line"
            : row?._from_wallet_id === props?.UserData?.data?.at(0)?._id
            ? "Buy"
            : "Sell",
          // Debit
          row?._from_wallet_id === props?.UserData?.data?.at(0)?._id
            ? Amount.toFixed(2)
            : row?._type === "credit_line_recovery"
            ? Amount.toFixed(2)
            : null,
          // credit
          row?._from_wallet_id !== props?.UserData?.data?.at(0)?._id
            ? row?._type === "add_coupon"
              ? Amount?.toFixed(2)
              : row?._from_wallet_id === "admin" && row?._type === "sell_coupon"
              ? Amount?.toFixed(2)
              : row?._from_wallet_id === "admin"
              ? null
              : Amount?.toFixed(2)
            : null,
          // TDS
          row?.INRDetails?.TDS ? row?.INRDetails?.TDS.toFixed(2) : null,
          // acc balance
          row._from_wallet_id === props?.UserData?.data?.at(0)?._id ||
          row._from_wallet_id === "admin"
            ? row?._from_wallet_balance?.toFixed(2)
            : row?._to_wallet_balance?.toFixed(2),
          // Credit Due Balance
          row?._type === "add_coupon"
            ? row?._from_wallet_credit_due
            : row?._from_wallet_id === "admin" && row?._type === "sell_coupon"
            ? row?._from_wallet_credit_due?.toFixed(2)
            : row?._from_wallet_id === "admin"
            ? row?._to_wallet_credit_due?.toFixed(2)
            : row?._from_wallet_id === props?.UserData?.data?.at(0)?._id
            ? row?._from_wallet_credit_due?.toFixed(2)
            : row?._to_wallet_credit_due?.toFixed(2),
          // Wallet Usable Balance
          row?._type === "add_coupon"
            ? row?._from_wallet_usable_balance
            : row?._from_wallet_id === "admin" && row?._type === "sell_coupon"
            ? row?._from_wallet_usable_balance?.toFixed(2)
            : row?._from_wallet_id === "admin"
            ? row?._to_wallet_usable_balance?.toFixed(2)
            : row?._from_wallet_id === props?.UserData?.data?.at(0)?._id
            ? row?._from_wallet_usable_balance?.toFixed(2)
            : row?._to_wallet_usable_balance?.toFixed(2),
          // Other
          otherBalance(row),
          // BXI Commission
          row?.INRDetails?.TotalBxiCommission?.toFixed(2) ||
            row?.INRDetails?.totalBxiCommission?.toFixed(2) ||
            0,
          // Assessable Value
          getTotalBalance(row) ? getTotalBalance(row).toFixed(2) : null,
          // GST
          getTotalGST(row) ? getTotalGST(row).toFixed(2) : null,
          // Total Invoice Value
          getTotalBalance(row) + getTotalGST(row)
            ? (getTotalBalance(row) + getTotalGST(row))?.toFixed(2)
            : null,
          // TDS
          row?.INRDetails?.TDS?.toFixed(2),
          // Net Receivable/(Payable)
          row?._from_wallet_id !== props?.UserData?.data?.at(0)?._id
            ? (
                getTotalBalance(row) +
                getTotalGST(row) -
                (row?.INRDetails?.TDS || 0)
              ).toFixed(2)
            : (getTotalBalance(row) + getTotalGST(row)).toFixed(2),
          // INR
          row?.INRDetails?.TotalInrToPay?.toFixed(2),
          // Coins
          row?._balance?.toFixed(2),
          // TDS (26AS)
          row._from_wallet_id !== props?.UserData?.data?.at(0)?._id
            ? row?.INRDetails?.TDS?.toFixed(2)
            : null,
          // Invoice Value
          getTotalBalance(row) + getTotalGST(row)
            ? (getTotalBalance(row) + getTotalGST(row))?.toFixed(2)
            : null,
        ];

        XLSX.utils.sheet_add_aoa(infoWs, [rowData], { origin: index + 7 });
      })
    );

    XLSX.utils.book_append_sheet(wb, infoWs, "Statement");

    XLSX.writeFile(
      wb,
      `${props?.UserData?.data?.at(0)?.CUID}-${new Date().toISOString()}.xlsx`
    );
  };

  return (
    <Button
      onClick={generatePDFStatement}
      sx={{
        background: "#2261A2",
        borderRadius: "17px",
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "12px",
        textTransform: "none",
        color: "#F8F8F8",
        width: "200px",
        height: "40px",
        "&:hover": {
          background: "#2261A2",
        },
      }}
    >
      <HiDownload fill="white" size={20} /> Download Statement
    </Button>
  );
};

const TableContainerStyle = {
  minHeight: "500px",
  maxHeight: "500px",
  overflowY: "auto",
  "::-webkit-scrollbar": {
    width: "3px",
    display: "flex",
    borderRadius: "3px",
    maxHeight: "6px",
    minHeight: "6px",
    minWidth: "6px",
    bgcolor: "#e8eaf1",
  },
  "::-webkit-scrollbar-thumb": {
    background: "#2261A2",
    borderRadius: "3px",
    cursor: "pointer",
  },
  "::-webkit-scrollbar-thumb:vertical": {
    maxWidth: "6px",
    minWidth: "6px",
  },
  "::-webkit-scrollbar-thumb:horizontal": {
    maxHeight: "6px",
    minHeight: "6px",
  },
};

const TableTitleStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "13px",
  lineHeight: "18px",
  color: "#6B7A99",
};

const TextStyleCommon = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "13px",
  lineHeight: "18px",
  color: "#000",
};

const TypographyComponent = ({ children }) => {
  return (
    <TableCell>
      <Typography sx={TextStyleCommon}>{children}</Typography>
    </TableCell>
  );
};
